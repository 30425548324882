import { render, staticRenderFns } from "./global-period.vue?vue&type=template&id=933e6f12&scoped=true&"
import script from "./global-period.vue?vue&type=script&lang=js&"
export * from "./global-period.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "933e6f12",
  null
  
)

export default component.exports